<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="BestAfterlanding" style="width:100vw">
  <div id="ab65dc64">
    <div id="d1b8e3a9">
      <img src="../assets/img-a6e0f36d.png" id="a6e0f36d"/>
      <div id="ab4e8125">
        <div id="b84342b2">
          您的专属产品经理
        </div>
        <div id="a7432f3d">
          韩柳
        </div>
        <div id="ffab44c8">
          金牌产品经理
        </div>
        <div id="b1ad65f5">
          电子邮箱：xxxxxxxxxx
        </div>
        <div id="af93faae">
          电话：xxxxxxxxxx
        </div>
      </div>
    </div>
    <div id="b3cd117d">
      让您的天才构想在 数周 内变为现实
    </div>
  </div>
  <div id="a931f49d">
    学会投
  </div>
  <div id="dea02d79">
    现阶段进程：开发中
  </div>
  <div id="a6612470">
    预计上线日期：2021年12月12日
  </div>
  <el-tabs id="a0e9df00" type="card">
    <el-tab-pane label="待确认事项">
      <div id="acceabcc">
        <div id="f8deda78">
          <div id="a5c82213">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="ac641809">
            待确认
          </div>
          <el-select id="b3531d09" v-model="val_b3531d09">
            <el-option v-for="item in op_b3531d09" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a91d57fd.png" id="a91d57fd"/>
        <div id="ba297a8b">
          <div id="a6668827">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="ac0d7ef2">
            待确认
          </div>
          <el-select id="af59d4bd" v-model="val_af59d4bd">
            <el-option v-for="item in op_af59d4bd" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-d8c0d417.png" id="d8c0d417"/>
        <div id="af42658d">
          <div id="adf0adde">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a4fd00c7">
            待确认
          </div>
          <el-select id="a2c67e68" v-model="val_a2c67e68">
            <el-option v-for="item in op_a2c67e68" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-ae020213.png" id="ae020213"/>
        <div id="a1fd7c19">
          <div id="a4d4eae6">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a1d2965f">
            待确认
          </div>
          <el-select id="a0569d8f" v-model="val_a0569d8f">
            <el-option v-for="item in op_a0569d8f" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-abb8268e.png" id="abb8268e"/>
        <div id="afcae475">
          <div id="a9aac5a8">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a729f2bc">
            待确认
          </div>
          <el-select id="a8e06474" v-model="val_a8e06474">
            <el-option v-for="item in op_a8e06474" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a106e5ed.png" id="a106e5ed"/>
        <div id="a3c97f34">
          <div id="b6bc9f53">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="d19310a6">
            待确认
          </div>
          <el-select id="ae735c28" v-model="val_ae735c28">
            <el-option v-for="item in op_ae735c28" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="已确认事项">
      <div id="a2499d7a">
        <div id="a2686bf9">
          <div id="a7a2c468">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a4358152">
            已确认
          </div>
          <el-select id="a8fa93ef" v-model="val_a8fa93ef">
            <el-option v-for="item in op_a8fa93ef" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a166aa17.png" id="a166aa17"/>
        <div id="d969c4f6">
          <div id="bf8b17ab">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="ae2f45b0">
            已确认
          </div>
          <el-select id="a8c1b5dc" v-model="val_a8c1b5dc">
            <el-option v-for="item in op_a8c1b5dc" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a7327eb3.png" id="a7327eb3"/>
        <div id="daf05077">
          <div id="fa041ae5">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a925d6cb">
            已确认
          </div>
          <el-select id="a249f966" v-model="val_a249f966">
            <el-option v-for="item in op_a249f966" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-eb54c85f.png" id="eb54c85f"/>
        <div id="e281d6a2">
          <div id="a00a99c1">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a448b699">
            已确认
          </div>
          <el-select id="cb0a193e" v-model="val_cb0a193e">
            <el-option v-for="item in op_cb0a193e" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-ae7936de.png" id="ae7936de"/>
        <div id="e7245019">
          <div id="ba89b5c4">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a878f0b0">
            已确认
          </div>
          <el-select id="a1a74b01" v-model="val_a1a74b01">
            <el-option v-for="item in op_a1a74b01" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a43e1726.png" id="a43e1726"/>
        <div id="ac0b1700">
          <div id="a8971515">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a91ebdc5">
            已确认
          </div>
          <el-select id="e7e2f3e9" v-model="val_e7e2f3e9">
            <el-option v-for="item in op_e7e2f3e9" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
  <div id="a23eacbd">
    需求文档
  </div>
  <img src="../assets/img-aaf9ab64.png" id="aaf9ab64"/>
  <img src="../assets/img-adf2376e.png" id="adf2376e"/>
  <img src="../assets/img-f1306c26.png" id="f1306c26"/>
  <img src="../assets/img-cc722aa8.png" id="cc722aa8"/>
  <img src="../assets/img-a1056598.png" id="a1056598"/>
  <img src="../assets/img-ebcd63a0.png" id="ebcd63a0"/>
  <div id="a16b38fb">
    设计文档
  </div>
  <img src="../assets/img-f1e290e4.png" id="f1e290e4"/>
  <img src="../assets/img-a88db8e5.png" id="a88db8e5"/>
  <img src="../assets/img-edea4b7d.png" id="edea4b7d"/>
  <img src="../assets/img-a15aea46.png" id="a15aea46"/>
  <div id="a44d7e5a">
    系统文档
  </div>
  <img src="../assets/img-a440a8af.png" id="a440a8af"/>
  <img src="../assets/img-cd3a9f0a.png" id="cd3a9f0a"/>
  <img src="../assets/img-d637b7c2.png" id="d637b7c2"/>
  <img src="../assets/img-e50af754.png" id="e50af754"/>
  <img src="../assets/img-acbd5c4e.png" id="acbd5c4e"/>
  <div id="ac0938b1">
    测试报告
  </div>
  <img src="../assets/img-b272e4e4.png" id="b272e4e4"/>
  <img src="../assets/img-af234622.png" id="af234622"/>
  <img src="../assets/img-d4e4585d.png" id="d4e4585d"/>
  <img src="../assets/img-a35e65dc.png" id="a35e65dc"/>
  <img src="../assets/img-a60e2d68.png" id="a60e2d68"/>
  <img src="../assets/img-fde97da1.png" id="fde97da1"/>
  <img src="../assets/img-a08bc7d6.png" id="a08bc7d6"/>
  <img src="../assets/img-a7fb6418.png" id="a7fb6418"/>
  <div id="ac366867">
    学会投
  </div>
  <div id="af17ce88">
    现阶段进程：运营中
  </div>
  <div id="a20094cd">
    预计上线日期：2021年12月12日
  </div>
  <div id="a64d7c57">
    已运营
  </div>
  <div id="fe39f3a7">
    <img src="../assets/img-b5e76183.png" id="b5e76183"/>
    <div id="a7b12656">
      <img src="../assets/img-bc65e7b8.png" id="bc65e7b8"/>
      <div id="acccdf3e">
        <img src="../assets/img-af65267f.png" id="af65267f"/>
        <div id="a147901f">
          过去24小时登录人数：
        </div>
        <div id="a079077a">
          xx人
        </div>
      </div>
      <div id="ad13a073">
        <img src="../assets/img-ae0aaa12.png" id="ae0aaa12"/>
        <div id="a5da58db">
          过去24小时使用流量：
        </div>
        <div id="b306b405">
          xxG
        </div>
      </div>
      <div id="dea5b549">
        <img src="../assets/img-aa6bba28.png" id="aa6bba28"/>
        <div id="a50e182a">
          过去24小时使用流量：
        </div>
        <div id="a033fd2b">
          xxG
        </div>
      </div>
    </div>
    <img src="../assets/img-b11812e4.png" id="b11812e4"/>
  </div>
  <div id="a347868c">
    <dal-compfloat id="cmpa347868c">
    </dal-compfloat>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
export default{
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
    op_b3531d09:[],
    val_b3531d09:'',
    op_af59d4bd:[],
    val_af59d4bd:'',
    op_a2c67e68:[],
    val_a2c67e68:'',
    op_a0569d8f:[],
    val_a0569d8f:'',
    op_a8e06474:[],
    val_a8e06474:'',
    op_ae735c28:[],
    val_ae735c28:'',
    op_a8fa93ef:[],
    val_a8fa93ef:'',
    op_a8c1b5dc:[],
    val_a8c1b5dc:'',
    op_a249f966:[],
    val_a249f966:'',
    op_cb0a193e:[],
    val_cb0a193e:'',
    op_a1a74b01:[],
    val_a1a74b01:'',
    op_e7e2f3e9:[],
    val_e7e2f3e9:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#ab65dc64{
  width:1323px;
  height:758px;
  position:relative;
  margin-left:162px;
  margin-top:100px;
}
#ab65dc64{
  background-image:url('../assets/img-ac3ea235.png');
  background-size: cover;
}
#d1b8e3a9{
  width:947px;
  position:relative;
  padding-left:205px;
  padding-top:50px;
}
#a6e0f36d{
  width:337px;
  height:337px;
  padding-bottom:36px;
  display:inline-block;
  vertical-align: middle;
}
#a6e0f36d{
  vertical-align:middle;
}
#ab4e8125{
  width:360px;
  position:relative;
  margin-left:250px;
  display:inline-block;
  vertical-align: middle;
}
#b84342b2{
}
#b84342b2{
  text-align:left;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#a7432f3d{
  margin-top:40px;
}
#a7432f3d{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#ffab44c8{
  margin-top:34px;
}
#ffab44c8{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#b1ad65f5{
  margin-top:34px;
}
#b1ad65f5{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#af93faae{
  margin-top:34px;
}
#af93faae{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#b3cd117d{
  margin-top:64px;
  padding-bottom:173px;
}
#b3cd117d{
  text-align:right;
  color:#6f7484;
  font-size:60px;
  font-family:PingFang SC;
}
#a931f49d{
  margin-top:100px;
}
#a931f49d{
  text-align:center;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#dea02d79{
  margin-top:30px;
}
#dea02d79{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a6612470{
  margin-top:10px;
}
#a6612470{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a0e9df00{
  width:1323px;
  margin-top:60px;
  padding-left:162px;
}
#a0e9df00{
  vertical-align: top;
}
#a0e9df00 /deep/ .el-tabs__item{
  font-size:30px;
  font-family:PingFang SC;
}
#acceabcc{
  width:1323px;
}
#acceabcc{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#f8deda78{
  width:1173px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#a5c82213{
  display:inline-block;
  vertical-align: middle;
}
#a5c82213{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ac641809{
  display:inline-block;
  vertical-align: middle;
}
#ac641809{
  text-align:center;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#f8deda78 .el-select{
  width:37px;
  margin-left:68px;
  padding-top:8px;
  padding-bottom:11px;
}
#a91d57fd{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a91d57fd{
  vertical-align:middle;
}
#ba297a8b{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#a6668827{
  display:inline-block;
  vertical-align: middle;
}
#a6668827{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ac0d7ef2{
  display:inline-block;
  vertical-align: middle;
}
#ac0d7ef2{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#ba297a8b .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#d8c0d417{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#d8c0d417{
  vertical-align:middle;
}
#af42658d{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#adf0adde{
  display:inline-block;
  vertical-align: middle;
}
#adf0adde{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a4fd00c7{
  display:inline-block;
  vertical-align: middle;
}
#a4fd00c7{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#af42658d .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#ae020213{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#ae020213{
  vertical-align:middle;
}
#a1fd7c19{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#a4d4eae6{
  display:inline-block;
  vertical-align: middle;
}
#a4d4eae6{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a1d2965f{
  display:inline-block;
  vertical-align: middle;
}
#a1d2965f{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#a1fd7c19 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#abb8268e{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#abb8268e{
  vertical-align:middle;
}
#afcae475{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#a9aac5a8{
  display:inline-block;
  vertical-align: middle;
}
#a9aac5a8{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a729f2bc{
  display:inline-block;
  vertical-align: middle;
}
#a729f2bc{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#afcae475 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a106e5ed{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a106e5ed{
  vertical-align:middle;
}
#a3c97f34{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
  padding-bottom:50px;
}
#b6bc9f53{
  display:inline-block;
  vertical-align: middle;
}
#b6bc9f53{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#d19310a6{
  display:inline-block;
  vertical-align: middle;
}
#d19310a6{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#a3c97f34 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a2499d7a{
  width:1323px;
}
#a2499d7a{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#a2686bf9{
  width:1173px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#a7a2c468{
  display:inline-block;
  vertical-align: middle;
}
#a7a2c468{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a4358152{
  display:inline-block;
  vertical-align: middle;
}
#a4358152{
  text-align:center;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#a2686bf9 .el-select{
  width:37px;
  margin-left:68px;
  padding-top:8px;
  padding-bottom:11px;
}
#a166aa17{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#a166aa17{
  vertical-align:middle;
}
#d969c4f6{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#bf8b17ab{
  display:inline-block;
  vertical-align: middle;
}
#bf8b17ab{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ae2f45b0{
  display:inline-block;
  vertical-align: middle;
}
#ae2f45b0{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#d969c4f6 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a7327eb3{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#a7327eb3{
  vertical-align:middle;
}
#daf05077{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#fa041ae5{
  display:inline-block;
  vertical-align: middle;
}
#fa041ae5{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a925d6cb{
  display:inline-block;
  vertical-align: middle;
}
#a925d6cb{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#daf05077 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#eb54c85f{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#eb54c85f{
  vertical-align:middle;
}
#e281d6a2{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a00a99c1{
  display:inline-block;
  vertical-align: middle;
}
#a00a99c1{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a448b699{
  display:inline-block;
  vertical-align: middle;
}
#a448b699{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#e281d6a2 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#ae7936de{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#ae7936de{
  vertical-align:middle;
}
#e7245019{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#ba89b5c4{
  display:inline-block;
  vertical-align: middle;
}
#ba89b5c4{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a878f0b0{
  display:inline-block;
  vertical-align: middle;
}
#a878f0b0{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#e7245019 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a43e1726{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#a43e1726{
  vertical-align:middle;
}
#ac0b1700{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
  padding-bottom:50px;
}
#a8971515{
  display:inline-block;
  vertical-align: middle;
}
#a8971515{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a91ebdc5{
  display:inline-block;
  vertical-align: middle;
}
#a91ebdc5{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#ac0b1700 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a23eacbd{
  margin-top:100px;
}
#a23eacbd{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#aaf9ab64{
  width:848px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#adf2376e{
  width:606px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#f1306c26{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#cc722aa8{
  width:485px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a1056598{
  width:606px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#ebcd63a0{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a16b38fb{
  margin-top:60px;
}
#a16b38fb{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#f1e290e4{
  width:848px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a88db8e5{
  width:606px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#edea4b7d{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a15aea46{
  width:485px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a44d7e5a{
  margin-top:60px;
}
#a44d7e5a{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#a440a8af{
  width:848px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#cd3a9f0a{
  width:606px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#d637b7c2{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#e50af754{
  width:485px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#acbd5c4e{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#ac0938b1{
  margin-top:60px;
}
#ac0938b1{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#b272e4e4{
  width:848px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#af234622{
  width:606px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#d4e4585d{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a35e65dc{
  width:485px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a60e2d68{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#fde97da1{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a08bc7d6{
  width:485px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#a7fb6418{
  width:727px;
  height:42px;
  margin-top:30px;
  padding-left:161px;
}
#ac366867{
  margin-top:100px;
}
#ac366867{
  text-align:center;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#af17ce88{
  margin-top:30px;
}
#af17ce88{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a20094cd{
  margin-top:10px;
}
#a20094cd{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a64d7c57{
  margin-top:60px;
}
#a64d7c57{
  text-align:center;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#fe39f3a7{
  width:1374px;
  position:relative;
  margin-top:30px;
  padding-left:137px;
  padding-bottom:502px;
}
#b5e76183{
  width:100px;
  height:100px;
  padding-top:440px;
  padding-bottom:443px;
  display:inline-block;
  vertical-align: middle;
}
#b5e76183{
  vertical-align:middle;
}
#a7b12656{
  width:974px;
  position:relative;
  margin-left:100px;
  display:inline-block;
  vertical-align: middle;
}
#a7b12656{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 6px 10px rgba(0,0,0,0.25);
}
#bc65e7b8{
  width:974px;
  height:573px;
}
#bc65e7b8{
  vertical-align:middle;
}
#acccdf3e{
  width:629px;
  position:relative;
  margin-top:60px;
  padding-left:172px;
}
#af65267f{
  width:50px;
  height:50px;
  padding-top:3px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#af65267f{
  vertical-align:middle;
}
#a147901f{
  display:inline-block;
  vertical-align: middle;
}
#a147901f{
  text-align:left;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#a079077a{
  display:inline-block;
  vertical-align: middle;
}
#a079077a{
  text-align:center;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#ad13a073{
  width:619px;
  position:relative;
  margin-top:61px;
  padding-left:172px;
}
#ae0aaa12{
  width:50px;
  height:50px;
  padding-top:3px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#ae0aaa12{
  vertical-align:middle;
}
#a5da58db{
  display:inline-block;
  vertical-align: middle;
}
#a5da58db{
  text-align:left;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#b306b405{
  display:inline-block;
  vertical-align: middle;
}
#b306b405{
  text-align:center;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#dea5b549{
  width:619px;
  position:relative;
  margin-top:61px;
  padding-left:172px;
  padding-bottom:60px;
}
#aa6bba28{
  width:50px;
  height:50px;
  padding-top:3px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#aa6bba28{
  vertical-align:middle;
}
#a50e182a{
  display:inline-block;
  vertical-align: middle;
}
#a50e182a{
  text-align:left;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#a033fd2b{
  display:inline-block;
  vertical-align: middle;
}
#a033fd2b{
  text-align:center;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#b11812e4{
  width:100px;
  height:100px;
  margin-left:100px;
  padding-top:440px;
  padding-bottom:443px;
  display:inline-block;
  vertical-align: middle;
}
#b11812e4{
  vertical-align:middle;
}
#a347868c{
  width:150px;
  position:fixed;
  z-index: 50;
  top:3343px;
  left:1740px;
}
#a347868c{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
</style>
